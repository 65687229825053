/* eslint-env jquery */
/* global debounce */
/* eslint-disable no-mixed-spaces-and-tabs, max-len, valid-typeof */
/* eslint-disable no-undef */
require( 'slick-carousel-latest' );
import { LottieAnimator } from '../../../../../plugins/berg/src/block/lottie-animator/js/core-event-handler';
import SVGAnimation from '../custom/SVGAnimation';
import { CountUp } from 'countup.js';
( function ( $, w ) {
  let windowWidth = $( w ).width();

  const initSlick = () => {
    if ( $( '[data-slick]' ).length ) {
      $( '[data-slick]' )
        .not( '.bs-slider-accordion-slick' )
        .not( '.slick-initialized' ).slick();
    }
  };

  const autoPlaySpeedFunction = () => {
    $( '.bs-tab-slider--progress-bar :not(.bs-slider-content)[data-slick]' ).each( ( index, ele ) => {
      if ( $( ele ).hasClass( 'slick-initialized' ) ) {
        const slick = $( ele ).slick( 'getSlick' );

        //append wrapper class and animator span to each slick slide element
        $.each( $( ele ).find( '.slick-slide' ), ( i, element ) => {
          const animatorElement = $( element ).find( '.slick-slide-wrapper .slick-slide-animator' );
          animatorElement.unwrap();
          animatorElement.remove();

          $( element ).find( '.slick-slide-wrapper' ).children().wrapAll( '<div class="slick-slide-title-wrapper"></div>' ); // eslint-disable-line
          const $content = $( element ).find( '.slick-slide-title-wrapper' );
          $content.append( '<span class="slick-slide-animator"></span>' );
        } );
        //set animator animation-duration
        if ( slick.options.autoplay === true ) {
          const playSpeed = slick.options.autoplaySpeed;
          $( ele )
            .find( '.slick-slide-animator' )
            .attr( 'data-speed', `${playSpeed}ms` )
            .css( 'animation-duration', `${playSpeed}ms` );
        }
      }
    } );
  };

  // To refresh the initiated slick sliders
  const refreshSlick = () => {
    $( '.slick-initialized' ).each( function ( key ) {
      if ( $( '.slick-initialized' )[key]?.slick ) {
        $( '.slick-initialized' )[key].slick.refresh();
      }
    } );
  };

  // To reset the autoplay enabled sliders to the initial slide when appearing on the viewport
  const resetSlidersWithIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const io = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          $.each( $( '.slick-initialized' ), ( index, element ) => {
            const slickSlider = $( element );

            if ( slickSlider.hasClass( 'stop-slider' ) ) {
              return;
            }

            const slickObject = slickSlider.slick( 'getSlick' );
            const initialSlide = slickObject.options.initialSlide;
            // Resetting only if the current slide is greater than the initial slide
            if ( slickObject.options.autoplay && slickObject.currentSlide > initialSlide ) {
              slickSlider.slick( 'slickGoTo', initialSlide );
            }
          } );
        }
      } );
    }, options );

    $.each( $( '.bs-slider' ).closest( '.bs-section' ), ( index, section ) => {
      io.observe( section );
    } );
    $.each( $( '.bs-tab-slider' ).closest( '.bs-section' ), ( index, section ) => {
      io.observe( section );
    } );
  };

  const debouncedHandlers = () => {
    const dynamicWidth = $( w ).width();
    if ( windowWidth !== dynamicWidth ) {
      initSlick();
      autoPlaySpeedFunction();
      refreshSlick();
      windowWidth = dynamicWidth;
    }
  };

  // To slide between first slide and last slide smoothly when the 'Infinite' mode is enabled
  const slider = $( '[data-slick]' );
  slider.on( 'beforeChange', ( e, slick, currentSlide, nextSlide ) => {
    
    $( e.target ).addClass( 'slide-moving-arrow-hide' );
    
    if ( slick.options.infinite ) {
      const slidesCount = slick.$slides.length,
        cls = 'slick-current slick-active' + ( slick.options.centerMode ? ' slick-center' : '' );
      let selector = null;
      if ( nextSlide === 0 ) {
        selector = `.slick-slide[data-slick-index="0"], .slick-slide[data-slick-index="${slidesCount}"]`;
      } else if ( nextSlide === slidesCount - 1 ) {
        selector = `.slick-slide[data-slick-index="-1"], .slick-slide[data-slick-index="${slidesCount - 1}"]`;
      }

      if ( !selector ) return;
      // Adding a timeout since we need to add the active class after the transition has started
      setTimeout( () => {
        $( '.slick-slide', slick.$slider ).removeClass( cls );
        $( selector, slick.$slider ).addClass( cls );
      }, 10 );
    }
  } );

  slider.on( 'afterChange', ( e, slick, currentSlide ) => {

    let counters = $( '.bs-slider-content .slick-slide ' ).eq( currentSlide ).find( '.bs-counter .bs-counter__count span' );
    
    $( e.target ).removeClass( 'slide-moving-arrow-hide' );
    
    if( counters.length > 0 ){
      counters.each( ( index, element ) => {
        let item = element;
        let dataOptions = $( item ).attr( 'data-options' );
        let counterOptions = JSON.parse( dataOptions );
        let countUp = new CountUp( item, counterOptions.endVal, counterOptions );
        if ( !countUp.error ) {
          countUp.start();
        }
      } );
    }
  } );

  /* Mobile slider Progress bar*/
  const updateProgressStates = (
    sliderTotal,
    uniqSliderId,
    currentSlider
  ) => {
    if ( sliderTotal != 0 ) {
      const columnSelector = $( '#' + uniqSliderId ).parents( '.bs-column' );
      let progressBarValue = ( currentSlider / sliderTotal ) * 100 + '%';
      columnSelector.find( '.progress-bar__inner' ).css( { width: progressBarValue } );
      columnSelector.find( '.progress-bar__slide-count' ).text( currentSlider + '/' + sliderTotal );
    }
  };

  $( '.bs-section--slider-progress-bar-light, .bs-section--slider-progress-bar-dark' ).each( ( index, element ) => {
    const $this = $( element );
    let $slider, $sliderParent;
    if ( $this.find( '.bs-tab-slider' ).length ) {
      $slider = $this.find( '.bs-slider-content' );
      $sliderParent = $slider.parent( '.bs-tab-slider' );
    }
    if ( $this.find( '.bs-slider' ).length ) {
      $slider = $this.find( '.slick-slider' );
      $sliderParent = $slider.parent( '.bs-slider' );
    }
    if ( !$this.find( '.progress-bar__container' ).length ) {
      $sliderParent.after(
        '<div class="progress-bar__container"> <div class="progress-bar__wrapper"> <span class="progress-bar__inner"></span> </div> <div class="progress-bar__slide-count"> <span class="progress-bar-slide-count"></span> </div> </div>'
      );
    }
    // get json settings
    const slickJsonData = $slider.attr( 'data-slick' );
    let getMobileSetting = {};
    if ( slickJsonData ) {
      const slickDataSet = JSON.parse( $slider.attr( 'data-slick' ) );
      getMobileSetting = slickDataSet.responsive[1].settings;
    }

    let slideToShow = 1;
    let slideToScroll = 1;
    if ( typeof getMobileSetting != 'undefined' ) {
      slideToShow = getMobileSetting.slidesToShow ? getMobileSetting.slidesToShow : slideToShow;
      slideToScroll = getMobileSetting.slidesToScroll ? getMobileSetting.slidesToScroll : slideToScroll;
    }

    $sliderParent.each( ( index, ele ) => {
      let uniqSliderId = $( ele ).find( '.slick-slider' ).attr( 'id' );

      let dataSlickLength = $( ele ).find(
        '.slick-slider .slick-slide'
      ).length;

      updateProgressStates( dataSlickLength, uniqSliderId, slideToShow );

      const uniqueSliderElement = $( '#' + uniqSliderId );
      uniqueSliderElement.on(
        'init reInit afterChange',
        ( event, slick ) => {
          let currentSlideNumber = 0,
            totalSlideCount = slick.slideCount ? slick.slideCount : 0,
            currentItem = slick.currentSlide + 1;

          if ( slideToShow < 2 ) {
            currentSlideNumber = currentItem ? currentItem : slideToShow;
          } else {
            let totalNextItems = currentItem + slideToScroll;
            let checkCurrent = totalNextItems >= slideToShow ? currentItem + 1 : totalNextItems;
            currentSlideNumber = currentItem >= slideToShow ? checkCurrent : slideToShow;
          }
          updateProgressStates(
            totalSlideCount,
            uniqSliderId,
            currentSlideNumber
          );
        }
      );
      if ( uniqueSliderElement.length != 0 & uniqueSliderElement ) {
        uniqueSliderElement.slick( 'unslick' );
        uniqueSliderElement.slick( uniqueSliderElement.data( 'slick' ) );
      }
    } );
  } );
  /* End Mobile Progress Bar*/

  /* Slider Lottie */
  $.fn.slickSliderLottieInitiator = function ( options ) {
    const settings = $.extend(
      {
        sequenceInterval: 0,
        circular: false,
        initialSlidesOnly: false,
        oneTimePlay: false,
      },
      options
    );
  
    const lottieQueue = [];
    let nextAnimator = null;
    const bottomRootMargin =
      $( window ).height() > $( window ).width() ? '-10%' : '-20%';
    const observerOptions = {
      root: null,
      rootMargin: '0px 0px ' + bottomRootMargin + ' 0px',
    };
  
    const addLottieToQueue = ( lottie ) => {
      let no = $( lottie ).data( 'slider-lottie-sequence' );
      if ( no === undefined ) {
        const animator = new LottieAnimator( lottie );
        animator.init();
        no = lottieQueue.length;
        $( lottie ).attr( 'data-slider-lottie-sequence', no );
        lottieQueue.push( animator );
      }
      return lottieQueue[no];
    };
  
    const initiateLotties = ( slides ) => {
      slides.find( '.bs-lottie-animator' ).each( ( i, lottie ) => {
        addLottieToQueue( lottie );
      } );
    };
  
    const clearNextAnimator = () => {
      if ( nextAnimator ) {
        nextAnimator.cancel();
      }
    };
  
    const animateLotties = ( slides ) => {
      const animatorChain = [];
      clearNextAnimator();
  
      const animateLottie = ( chainObject ) => {
        if ( !chainObject ) {
          return;
        }
        const lottieAnimation = chainObject.animator.lottieAnimation;
        lottieAnimation.goToAndStop( 0, true );
        lottieAnimation.play();
        lottieAnimation.addEventListener(
          'complete',
          ( () => {
            nextAnimator = debounce( () => {
              animateLottie( chainObject.next );
            }, settings.sequenceInterval );
            lottieAnimation.removeEventListener( 'complete' );
            return nextAnimator;
          } )()
        );
      };
  
      let j = 0;
      slides.find( '.bs-lottie-animator' ).each( ( i, lottie ) => {
        if ( settings.oneTimePlay ) {
          const $lottie = $( lottie );
          if ( $lottie.hasClass( 'played' ) ) {
            return;
          }
          $lottie.addClass( 'played' );
        }
        const chainObject = {
          animator: addLottieToQueue( lottie ),
        };
        if ( j > 0 ) {
          animatorChain[j - 1].next = chainObject;
        }
        animatorChain.push( chainObject );
        j++;
      } );
  
      if ( animatorChain.length === 0 ) return;
      if ( settings.circular ) {
        animatorChain[animatorChain.length - 1].next = animatorChain[0];
      }
      animateLottie( animatorChain[0] );
    };
  
    const getSlickSetting = ( slick, setting, defaultValue = null ) => {
      const windowWidth = $( window ).width();
      for ( const breakpoint in slick.breakpointSettings ) {
        if ( windowWidth <= breakpoint ) {
          return slick.breakpointSettings[breakpoint][setting];
        }
      }
      return defaultValue;
    };
  
    const getActiveSlides = ( slider, slick ) => {
      const slideToShow = getSlickSetting( slick, 'slidesToShow', 1 );
      const slides = slider.find( '.slick-slide' );
      const firstActiveSlide = slides.filter( '.slick-active' ).eq( 0 ).index();
      return slides.slice( firstActiveSlide, firstActiveSlide + slideToShow );
    };
  
    $( this ).each( ( i, currSlider ) => {
      const $slider = $( currSlider );
      $slider.on( 'init reInit', ( event, slick ) => {
        const slides = $slider.find( '.slick-slide' );
        const activeSlides = getActiveSlides( $slider, slick );
        initiateLotties( slides );
        if ( settings.oneTimePlay ) {
          activeSlides.find( '.bs-lottie-animator' ).removeClass( 'played' );
        }
        const observer = new IntersectionObserver( ( entries ) => {
          if ( entries[0].isIntersecting ) {
            setTimeout( () => animateLotties( activeSlides ), 200 );
            observer.disconnect();
          }
        }, observerOptions );
        observer.observe( currSlider );
      } );
      if ( !settings.initialSlidesOnly ) {
        $slider.on( 'afterChange', ( event, slick ) => {
          const activeSlides = getActiveSlides( $slider, slick );
          animateLotties( activeSlides );
        } );
      }
    } );
  };

  $(
    '.bs-slider--animate-lottie > div, .bs-slider--animate-lottie .bs-slider-content'
  ).slickSliderLottieInitiator( {
    sequenceInterval: 2000,
    circular: true,
  } );
  /* End */

  // our value icon animation start
  const svgAnimatedRows = document.querySelectorAll( '.bs-row--common-svg-animation' );
  window.addEventListener( 'resize', ( ) => {
    setTimeout( () => {
      svgAnimatedRows.forEach( ( row ) => {
        const svgAnimation = new SVGAnimation( row );
        if ( screen.width > 991 ) {
          svgAnimation.restart();
        }
      } );
    },1000 );
  } );
  document.addEventListener( 'readystatechange', () => {
    if( document.readyState === 'complete' ) {
      // this is not working with debounce. so added setTimeout
      setTimeout( () => {
        svgAnimatedRows.forEach( ( row ) => {
          const svgAnimation = new SVGAnimation( row );
          slider.on( 'afterChange', ( ) => {
            if ( screen.width < 992 ) {
              svgAnimation.restart();
            }
          } );
          svgAnimation.init();
        } );
      },1000 );
    }
  } );  
  // our value icon animation end

  // Calls when the window is fully loaded
  $( w ).on( 'load', () => {
    initSlick();
    autoPlaySpeedFunction();
    resetSlidersWithIntersectionObserver();
  } );

  // Calls on window resize
  $( w ).on( 'resize', debounce( debouncedHandlers, 500 ) );
} )( jQuery, window );

export default class SVGAnimation {
  constructor( row ) {
    this.row = row;
    this.svgQueue = [];
    this.isCircular = row.classList.contains( 'bs-row--animation-repeat' );
    this.interval = 1000;
    this.activeTimeout = null;
    this.observer = new IntersectionObserver( ( entries ) => {
      this.observerHandler( entries );
    }, {
      root: null,
      rootMargin: '-15%',
    } );
  }
  restart() {
    this.terminate();
    this.fetchSVGs();
    this.play();
  }
  hasSlides() {
    return this.row.querySelectorAll( '.slick-slide' ).length !== 0;
  }
  fetchSVGs() {
    this.svgQueue = [];
    this.row.querySelectorAll( '.slick-slide-wrapper' ).forEach( slideWrapper => {
      if ( this.hasSlides() && slideWrapper.closest( '.slick-active' ) === null ) return;
      const svgatorObject = slideWrapper.querySelector( '.wp-block-e25m-svg-animation-block object' );
      const svgatorDocument = svgatorObject.contentDocument || svgatorObject.contentWindow.document;
      const svgatorElement = svgatorDocument.querySelector( 'svg' );
      this.svgQueue.push( svgatorElement );
    } );
  }
  play( index = 0 ) {
    if ( this.svgQueue.length === 0 ) return;
    if ( this.svgQueue.length === index && this.hasSlides() === false ) {
      if ( !this.isCircular ) return;
      index = 0;
    }
    if( !this.svgQueue[index] ) return;
    const player = this.svgQueue[index].svgatorPlayer;
    player.on( 'end', () => {
      this.activeTimeout = setTimeout( () => {
        this.play( index + 1 );
      }, this.interval );
      player.off( 'end' );
    } );
    player.play();
  }
  terminate(){
    if( this.activeTimeout ){
      this.svgQueue.forEach( ( svg ) => svg.svgatorPlayer.stop() );
      clearTimeout( this.activeTimeout );
      this.activeTimeout = null;
    }
  }
  observerHandler( entries ) {
    if ( entries[0].isIntersecting ) {
      if( this.isCircular ){
        this.observer.disconnect();
      }
      this.terminate();
      this.play();
    }
  }
  init() {
    this.fetchSVGs();
    this.observer.observe( this.row );
  }
}